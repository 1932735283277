export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "LABELS": {
          "ADVANCE_SERACH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busqueda avanzada"])},
          "FOLIO_OC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folio Orden de Compra"])},
          "FOLIO_SUPPLIERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folio Compra proveeedor"])},
          "NAME_SUPPLIERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre Proveedor"])},
          "STATE_RECEPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado recepción"])},
          "DATE_DELIVERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de entrega"])}
        },
        "BUTTONS": {
          "SEARCH_RECEPTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar recepciones"])},
          "CLEAN_FILTERS_FORM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpiar filtros"])}
        }
      }
    }
  })
}
