
import { computed, defineComponent, onMounted, reactive, ref } from 'vue';
import ModuleMaster from "../../../components/c-module-master/module-master.vue";
import { useRouter } from "vue-router";
import Datatable from "../../../components/c-datatable/Datatable.vue";
import Datarow from "../../../components/c-datatable/Datarow.vue";
import ApiService from "@/core/services/ApiService";
import { formatToDatatable } from "../../../common/formaterToDatatable";
//import Familiesform from "./Familiesform.vue";
import SwalMessageService from "@/core/services/SwalMessageService";
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import { getMessageError } from '@/core/helpers/messageFromPulmeros';
import TotalReception from './total-reception.vue';
import InputText from '@/components/forms/InputText.vue'
import Select from '@/components/forms/Select.vue'
import SelectFilterRemote from '@/components/forms/SelectFilterRemote.vue'
import DatePickerRange from '@/components/forms/DatePickerRange.vue'
import { Form } from 'vee-validate'
import tableModule from '@/store/modules/receptions/modules/table'
import ReceptionRow from '@/models/receptions/ReceptionRow';
import FiltersReceptions from '@/models/receptions/filters/FilterReceptions';
import * as Validations from "yup";
import { getOptions } from '@/core/enums/receptions-status';
import { toggleCollapse } from '@/core/helpers/dom'
import _ from "lodash";

export default defineComponent({
    components: {
        ModuleMaster,
        Datatable,
        Datarow,
        TotalReception
        // CrudActions,
        ///Familiesform
        , InputText
        , Form
        , Select
        , SelectFilterRemote
        , DatePickerRange
    },
    setup() {
        //VARIABLES Y CONSTANTESzptPurchaseOrders";
        const router = useRouter();
        const messageService = new SwalMessageService();
        let modeForm = ref("N");
        let titleEditForm = ref("Nueva Recepción");

        const btnForm = ref(null);
        const editForm = ref(null);
        const currentItem = ref(null);
        let idCollapse = ref("filter-controls");
        let purchaseOrderId = ref("");
        const labels = reactive({
            placeHolderToFieldSearch: "Buscar",
            titleNewCrudAction: "Recepcionar",
        });


        const configTable = reactive([
            { PropName: "id", HeadLabel: "id", Type: "text", VisibleInGrid: false },
            { PropName: "folio", HeadLabel: "Folio", Type: "text", VisibleInGrid: true },
            { PropName: "supplierName", HeadLabel: "Proveedor", Type: "text", VisibleInGrid: true },
            { PropName: "statusReceptionName", HeadLabel: "Estado Recepción", Type: "text", VisibleInGrid: true },
            { PropName: "createdAt", HeadLabel: "Fecha Creación", Type: "date", VisibleInGrid: true },
            { PropName: "dateOfDelivery", HeadLabel: "Fecha Entrega", Type: "date", VisibleInGrid: true },
        ]);

        //FILTROS
        const formFilters = ref();
        const btnFilters = ref();
        const validationSchemaFilters = Validations.object().shape({
            folio: Validations.string().default('')
            , supplierFolio: Validations.string().default('')
            , supplierIds: Validations.array().default([])
            , startDate: Validations.date().default(undefined).optional()
            , endDate: Validations.date().default(undefined).optional()
            , filterStatus: Validations.mixed().oneOf(getOptions().map(s => s.id))//solo acepta enumerador de recepciones
        })
        const optionsStatus = ref(getOptions());

        let currentPage = ref(1);
        let textSearch = ref("");

        onMounted(() => {
            setCurrentPageBreadcrumbs("Recepciones", ["Módulos", "Compras"]);
            //resetFilterDateInitial();
            tableModule.UPDATE_FILTERS({});
             setTimeout(() => {
                formFilters.value?.setValues({
                    ...tableModule.filters
                })
            }, 800);
        });

        //VARIABLES COMPUTADAS
        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });

        const renderRows = computed(() => {
            return formatToDatatable(tableModule.getRecordsTable, configTable, "isActive");
        });

        const currentFamiles = computed(() => {
            return currentItem.value;
        });

        const titleEditFormAction = computed(() => {
            return titleEditForm.value;
        });

        const totalRecords = computed(() => {
            return tableModule.totalRecords;
        });

        const selectedPage = computed(() => {
            return tableModule.pagination.currentPage;
        });

        const modeEditForm = computed(() => {
            return modeForm.value;
        });

        const getPurchaseOrderId = computed(() => purchaseOrderId.value )
        const suppliersOptions = computed(() => tableModule.selects.supplierOptions)
        const pageSize = computed(() => tableModule.pagination.pageSize)
        const folio = computed(() => tableModule.filters.folio)
        //FUNCIONES
        const handleChangePage = async(page: number) => {
            //await goToPage(page);
            tableModule.CHANGE_PAGE({
                currentPage: page
            })
        }

        // const changeStatusItem = async(item) => {
        //     let activate = !item.isActive;
        //     let url = `Families/${item.id}/${activate ? 'activate' : 'deactivate'}`;
        //     await ApiService.put(url, {}).then(res => {
        //         item.isActive = activate;
        //         handleItemSaved(item);
        //         messageService.success(`El registro se ${activate ? 'activó' : 'desactivó'} exitosamente.`);          
        //     }).catch(reject => {
        //         getMessageError(reject, messageService);
        //     });
        // }

        const handleItemSaved = (item) => {
            let poRecepted: null | ReceptionRow = null;
            switch(modeForm.value) {
                case "N":
                    ApiService.get(`PurchaseOrders/${purchaseOrderId.value}`).then(res => {
                        poRecepted = tableModule.records.find(x => x.id == purchaseOrderId.value) ?? null;
                        if(poRecepted) {
                            poRecepted.status = res.data.status;
                            poRecepted.statusReceptionName = res.data.statusReceptionName;
                        }
                        currentItem.value = res.data;
                    }).catch(reject => {
                        getMessageError(reject, messageService);
                    });
                    poRecepted = tableModule.records.find(x => x.id == item.id) ?? null;
                    if(poRecepted) {
                        poRecepted.status = 1;
                        poRecepted.statusReceptionName = "Surtido";
                    }
                    break;
                case "M":
                    updateRowItem(item);
                    break;
                case "E":
                     updateStatusRow(item.Id, item.status);
                    break;
            }
        }

        const updateRowItem = (item) => {
            let itemRow = tableModule.records.find(ir => ir.id === item.Id);
            if(itemRow) {
                itemRow.supplierName = item.name;
            }
        }

        const updateStatusRow = (idRow, status) => {
            let itemRow = tableModule.records.find(ir => ir.id === idRow);
            if(itemRow) {
                itemRow.status = status;
            }
        }

        const goToReceptionDetails = (rowID, isTotalReception = false) => {
            const selectRow = tableModule.records.find(ir => ir.id === rowID);
            tableModule.SET_SELECT_ROW(selectRow);
            let dataProps = `purchaseOrderId:${rowID}`;
            router.push({
                name: '/shopping/adminReceptions/:C0',
                params: {
                    C0: btoa(dataProps)
                }, query: {
                    isTotalReception: isTotalReception+''
                }
            });
        }

        const searchReceptions = (filters: FiltersReceptions) => { 
            tableModule.UPDATE_FILTERS(filters);
            toggleCollapse("#" + idCollapse.value);
        }
        const cleanFilters = () => {
            formFilters.value?.resetForm();
            resetFilterDateInitial();
            tableModule.RESET_FILTERS();
            btnFilters.value?.click();
        }
        const searchSuppliers = _.debounce((filterText: string) => {
            tableModule.getComboSupplier(filterText);
        }, 550, { leading: false, trailing: true });

        /**
         * Resetea la busqeuda inicial por fecha de los filtros
         */
        const resetFilterDateInitial = () => { 
            const startDate: Date = new Date();
            //const endDate: Date = addDays(startDate, 15);
            const endDate = new Date(startDate);
            endDate.setDate(endDate.getDate() - 15);

           /* tableModule.UPDATE_FILTERS({
                startDate: startDate.toISOString()
                , endDate: endDate.toISOString()
                , filterStatus: undefined
                , folio: ''
                , supplierFolio: ''
                , supplierIds: []
            });*/
            formFilters.value?.setFieldValue('startDate', startDate.toISOString())
            formFilters.value?.setFieldValue('endDate', endDate.toISOString())
        }

        const searchItems = async(text) => {
            currentPage.value = 1;
            textSearch.value = text;
            //tableModule.SET_TEXT_FILTER({})
            //tableModule.SEARCH_TABLE_DATA()
        }


        return {
            //variables y constantes
            labels,
            btnForm,
            editForm,
            purchaseOrderId
            , formFilters
            , btnFilters
            , optionsStatus
            , idCollapse

            //varaibles computadas
            ,headers,
            renderRows,
            titleEditFormAction,
            totalRecords,
            selectedPage,
            currentFamiles,
            modeEditForm,
            getPurchaseOrderId
            , validationSchemaFilters
            , suppliersOptions
            , pageSize
            , folio

            //funciones
            ,handleChangePage,
            //handleCrudAction,
            handleItemSaved
            //, onClickTotalReception
            , goToReceptionDetails
            , searchReceptions
            , cleanFilters
            , searchSuppliers
            , searchItems
        }
        
    },
})
